import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Notifications from 'vue-notification'
import VueMask from 'v-mask'
import { BootstrapVue, BTable } from 'bootstrap-vue'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core";
import {far} from "@fortawesome/free-regular-svg-icons";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'

library.add(far)

Vue.use(Notifications)
Vue.use(VueMask)
Vue.use(BootstrapVue)


window.axios = require('axios')
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.axios.defaults.baseURL = 'https://laboratoriounimed.unimed-fno.com.br:9104';

const store = Vue.observable({
  state: {
    id: null,
    idd: null,
    user: null,
    userType: 'all',
    password: null,
    lType: null,
    token: null,
  },
  laudos: []
})
const actions = {
  clearAll(state) {
    if (state.id)
      state.id = null;
    if (state.idd)
      state.idd = null;
    state.user = null;
    state.userType = "all";
    state.password = null;
    state.lType = null;
    state.token = null;
  },
  setLaudos(lst) { 
    store.laudos = lst
  },
  clearLaudos() { 
    store.laudos = []
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserType(state, userType) {
    state.userType = userType;
  },
  setFilial(state, filial) {
    state.idd = filial;
  },
  setToken(state, token) {
    state.token = token;
  },
  setPassword(state, pass) {
    state.password = pass;
  },
  setUserID(state, id) {
    state.id = id;
  },
  setLoginType(state, tp) {
    state.lType = tp;
  },
  isLoggedIn(state) {
    return !!state.token;
  },
}

Vue.prototype.$store = store
Vue.prototype.$actions = actions

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

//axios.defaults.withCredentials = true
// axios.defaults.baseURL = '' TODO(pac): No futuro verificar a possibilidade de vincular a URL base da API
Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
